/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-connect-container {
  max-width: 1024px;
  margin: 32px auto;
  padding: 32px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #faad14;
}
.drone-connect-row {
  margin-bottom: 24px;
}
.drone-connect-row:last-child {
  margin-bottom: 0;
}
.drone-connect-row .topic {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-bottom: 8px;
  font-size: 1.2em;
  color: #faad14;
}
.drone-connect-row .description {
  font-size: 0.9em;
  margin-bottom: 8px;
}
