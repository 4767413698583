/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-section {
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}
.drone-notes-section {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
  min-width: 0;
  margin-right: -16px;
}
.drone-notes-section > div {
  flex-grow: 1;
  min-width: 275px;
  margin-bottom: 16px;
  margin-right: 16px;
}
.drone-notes-section .drone-notes-list::v-deep .note-list {
  border: 1px solid #d9d9d9;
}
.service-pane {
  background-image: url("~@assets/images/bg_service.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 200px 200px;
  min-height: 250px;
}
.ticket-pane {
  background-image: url("~@assets/images/bg_ticket.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 200px 200px;
  min-height: 250px;
}
